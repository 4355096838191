const searchInputs = document.querySelectorAll('#search-textbox');

if (searchInputs.length) {
  searchInputs.forEach((searchInput) => {
    const form = searchInput.closest('form[action]');
    const resultsPanel = form && form.querySelector('.algolia-results');

    if (form && resultsPanel) {
      searchInput.addEventListener('input', (event) => {
        event.preventDefault();

        // To restore current query from URL
        //
        /* const location = new URL(window.location.href);
         const queryParam = location.searchParams.get('q');
         const query = queryParam ? `q=${encodeURIComponent(queryParam)}` : '';

         const url = `${form.action}.json${query}`; */

        const url = `${form.action}.json?q=${searchInput.value}`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              resultsPanel.innerHTML = data.html;
            }
          });
        });
    }
  });
}