import 'swiper/css';
import 'swiper/css/pagination';
import './css/main.css';

// import './js/thamesside.js';
// import './js/list.min.js';
// import './js/mobile-panel.js';
// import './js/slidebars.min.js';
// import './js/smooth-scroll.min.js';
// import './js/bricklayer.min.js';

import './js/search.js';

// core version + navigation, pagination modules:
import Swiper, {
    Navigation,
    Pagination,
    Autoplay,
    EffectFade
} from 'swiper';
// import Swiper and modules styles


import 'lazysizes';

//   import 'swiper/css/navigation';
//   import 'swiper/css/pagination';

// import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

var Isotope = require('isotope-layout');
import 'isotope-packery';
var imagesLoaded = require('imagesloaded');

import InfiniteScroll from 'infinite-scroll';
InfiniteScroll.imagesLoaded = imagesLoaded;

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

var List = require('list.js');

import Zooming from 'zooming';



//   ---------------------------------- Lazy Load ----------------------------------

document.addEventListener('lazyloaded', function (e) {
    if (e.target.parentElement.classList.contains("imagebackground")) {
        setTimeout(function () {
            e.target.parentElement.style.backgroundColor = "transparent"
        }, 1000);
    } else {
        setTimeout(function () {
            e.target.style.backgroundColor = "transparent"
        }, 1000);
    };
});



//   ---------------------------------- Swiper ----------------------------------

const swiper = document.getElementsByClassName("swiper");
if (swiper.length > 0) {

    const swiper = new Swiper('.swiper', {
        // Optional parameters
        loop: true,
        autoplay: {
            delay: 3000,
        },
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        centeredSlides: true,
        spaceBetween: 0,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });


} else false;


const swiperinline = document.getElementsByClassName("swiper-inline");
if (swiperinline.length > 0) {

    var swiperinline2 = document.getElementsByClassName("swiper-inline");
    var sw;
    for (sw = 0; sw < swiperinline2.length; sw++) {
        var loopnum = swiperinline2[sw].getAttribute("data-caronum");
        var swipername = '#swiper-inline-' + loopnum;
        var next = '#swiper-button-next-' + loopnum;
        var prev = '#swiper-button-prev-' + loopnum;
        const swiperinline = new Swiper(swipername, {
            // Optional parameters
            loop: true,
            preloadImages: false,
            slidesPerView: 1,
            watchSlidesVisibility: true,
            centeredSlides: true,
            spaceBetween: 64,
            // If we need pagination
            pagination: {
                el: '#swiper-pagination-',
                clickable: true,
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
        });
    }




} else false;





var swipertile = document.getElementsByClassName("swipertile");
if (swipertile.length > 0) {

    var o;
    for (o = 0; o < swipertile.length; o++) {

        var index = swipertile[o].getAttribute("data-index");
        var swiperdiv = "#swipertile-" + index;

        const swipertileswipe = new Swiper(swiperdiv, {
            // Optional parameters
            loop: true,
            preloadImages: false,
            slidesPerView: 1,
            watchSlidesVisibility: true,
            centeredSlides: true,
            spaceBetween: 0,
            // If we need pagination
            navigation: {
                nextEl: '#tile-right-' + index,
                prevEl: '#tile-left-' + index,
            },
        });

    };


} else false;



const swiperfeat = document.getElementsByClassName("swiper-features");
if (swiperfeat.length > 0) {

    const swiperfeat = new Swiper('.swiper-features', {
        // Optional parameters
        loop: true,
        autoplay: {
            delay: 2000,
        },
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        centeredSlides: true,
        spaceBetween: 0,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });


} else false;


const swiperSingle = document.getElementsByClassName("swiper-single");
if (swiperSingle.length > 0) {


    var swiperlength = document.getElementById("swiper-single").getAttribute("data-loopnum");

    const swipersingles = new Swiper('.swiper-single', {
        // Optional parameters
        loop: true,
        init: false,
        preloadImages: true,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
        centeredSlides: true,
        spaceBetween: 64,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    swipersingles.on('afterInit', function () {
        var currentslide = swipersingles.realIndex;
        var caption = swipersingles.slides[currentslide].getAttribute("data-caption");

        document.getElementById("gallery-caption").innerHTML = caption;


        var mobilecaption = document.getElementById("gallery-caption-mobile");
        if (typeof (mobilecaption) != 'undefined' && mobilecaption != null) {
            mobilecaption.innerHTML = caption;
        } else false;

        galleryNumber(swiperlength, currentslide);

        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

        // console.log("Swiper init");
    });



    swipersingles.on('slideChange', function () {
        var currentslide = swipersingles.realIndex;
        var caption = swipersingles.slides[currentslide].getAttribute("data-caption");

        document.getElementById("gallery-caption").innerHTML = caption;

        var mobilecaption = document.getElementById("gallery-caption-mobile");
        if (typeof (mobilecaption) != 'undefined' && mobilecaption != null) {
            mobilecaption.innerHTML = caption;
        } else false;

        galleryNumber(swiperlength, currentslide);


        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

        // console.log("Swiper init");
    });


    swipersingles.init();

    //   setTimeout(function () {
    //     swipersingles.update();
    //    }, 500);




} else false;




function galleryNumber(swiperlength, currentslide) {
    var galleryno = document.getElementById("current-gallery-no");
    var galleryrange = document.getElementById("gallery-no-items");
    var maindiv = document.getElementById("info-button");
    if (typeof (galleryno) != 'undefined' && galleryno != null) {
        maindiv.style.display = "inline-block";
        galleryno.innerHTML = currentslide + 1;
        galleryrange.innerHTML = parseInt(swiperlength);
    } else false;
};




//   ---------------------------------- Map ----------------------------------


var map = document.getElementById("map");
if (typeof (map) != 'undefined' && map != null) {

    mapboxgl.accessToken = 'pk.eyJ1IjoiZGV6YmF0IiwiYSI6IktBYTJ6UGcifQ.okynHEfjHSMnbH63aZEbvQ';
    const map = new mapboxgl.Map({
        container: 'map', // container ID
        style: 'mapbox://styles/dezbat/ckt4itpqf13uu18s1bkb5hx6g', // style URL
        center: [-0.047317, 51.525311], // starting position [lng, lat]
        zoom: 11 // starting zoom
    });

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    var i;
    var buildings = document.getElementsByClassName("building-map");
    for (i = 0; i < buildings.length; i++) {
        var lat = parseFloat(buildings[i].getAttribute("data-lat"));
        var long = parseFloat(buildings[i].getAttribute("data-long"));


        var markerdiv = buildings[i].querySelector(".map-popup");

        var markerpin = buildings[i].querySelector(".map-markerpin");

        const marker = new mapboxgl.Marker(markerpin, {
                anchor: 'bottom'
            })
            .setLngLat([long, lat])
            .setPopup(new mapboxgl.Popup({
                    offset: 30,
                })
                .setDOMContent(markerdiv)
            )
            .addTo(map);

    };

} else false;


var maplocal = document.getElementById("map-local");
if (typeof (maplocal) != 'undefined' && maplocal != null) {

    var centerlong = maplocal.getAttribute("data-centerlong");
    var centerlat = maplocal.getAttribute("data-centerlat");

    mapboxgl.accessToken = 'pk.eyJ1IjoiZGV6YmF0IiwiYSI6IktBYTJ6UGcifQ.okynHEfjHSMnbH63aZEbvQ';
    const map = new mapboxgl.Map({
        container: 'map-local', // container ID
        style: 'mapbox://styles/dezbat/ckt4itpqf13uu18s1bkb5hx6g', // style URL
        center: [centerlong, centerlat], // starting position [lng, lat]
        zoom: 15 // starting zoom
    });

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    var i;
    var buildings = document.getElementsByClassName("building-map");
    for (i = 0; i < buildings.length; i++) {
        var lat = parseFloat(buildings[i].getAttribute("data-lat"));
        var long = parseFloat(buildings[i].getAttribute("data-long"));


        var markerdiv = buildings[i].querySelector(".map-popup");

        var markerpin = buildings[i].querySelector(".map-markerpin");

        const marker = new mapboxgl.Marker(markerpin, {
                anchor: 'bottom'
            })
            .setLngLat([long, lat])
            .setPopup(new mapboxgl.Popup({
                    offset: 30,
                })
                .setDOMContent(markerdiv)
            )
            .addTo(map);

    };

} else false;



//   ---------------------------------- Menu ----------------------------------

var menubutton = document.getElementById("menu-button");

menubutton.addEventListener("click", menuToggle);

function menuToggle() {
    var menubutton = document.getElementById("menu-button");
    var status = menubutton.getAttribute("data-menustatus");
    var menu = document.getElementById("menu");
    var searchbutton = document.getElementById("search-button");
    var searchstatus = searchbutton.getAttribute("data-searchstatus");

    if (searchstatus === "o") {
        searchToggle();
    };

    if (status === "c") {
        menu.classList.remove("hidden");
        setTimeout(function () {
            menu.classList.remove("-translate-y-full")
        }, 10);
        menubutton.innerHTML = "Close";
        menubutton.setAttribute("data-menustatus", "o");
    } else {
        setTimeout(function () {
            menu.classList.add("hidden");
        }, 500);
        menu.classList.add("-translate-y-full");
        menubutton.innerHTML = "Menu";
        menubutton.setAttribute("data-menustatus", "c");
    };


};

window.menuToggle = menuToggle;


function menuSubs(sub, loop) {
    var submenu = document.getElementById("sub-" + sub);
    var button = document.getElementById("sub-button-" + sub);
    var substatus = submenu.getAttribute("data-status");
    var sub = document.getElementsByClassName("mobile-submenu");
    var allbuttons = document.getElementsByClassName("sub-buttons");
    var z;


    if (substatus === "c") {

        for (z = 0; z < sub.length; z++) {

            sub[z].classList.add("opacity-0");
            sub[z].style.maxHeight = "0px";

            console.log(z);

            setTimeout(hidesub(), 1000);

            function hidesub() {
                sub[z].classList.add("hidden");
            };
            sub[z].setAttribute("data-status", "c");
            allbuttons[z].classList.remove("rotate-45");

        };


        console.log("opening");
        submenu.classList.remove("hidden");
        setTimeout(function () {
            submenu.style.maxHeight = "5000px";
        }, 10);
        setTimeout(function () {
            submenu.classList.remove("opacity-0");
        }, 500);
        submenu.setAttribute("data-status", "o");
        button.classList.add("rotate-45");




        // if (loop != "noloop") {
        //     closeOtherSubs(sub);
        // };
    } else {
        console.log("closing");
        submenu.classList.add("opacity-0");
        submenu.style.maxHeight = "0px";
        setTimeout(function () {
            submenu.classList.add("hidden");
        }, 1000);
        submenu.setAttribute("data-status", "c");
        button.classList.remove("rotate-45");

    };
};

window.menuSubs = menuSubs;

// function closeOtherSubs(current) {
//     var sub = document.getElementsByClassName("mobile-submenu");
//     var z;
//     var currentCont = document.getElementById("sub-" + current);
//     // console.log(sub + z);

//     for (z = 0; z < sub.length; z++) {

//         var subId = sub[z].getAttribute("data-subname");
//         console.log(subId);
//         if (subId === current) {

//         } else {
//             console.log("Sub doesnt match current: " + subId);
//             menuSubs(subId, "noloop");
//         };
//     };
// };


//   ---------------------------------- Search modal ----------------------------------

var searchbutton = document.getElementById("search-button");
var searchbuttonmob = document.getElementById("search-button-mobile");

searchbutton.addEventListener("click", searchToggle);
searchbuttonmob.addEventListener("click", searchToggle);

function searchToggle() {
    var searchbutton = document.getElementById("search-button");
    var searchbuttonmob = document.getElementById("search-button-mobile");
    var searchstatus = searchbutton.getAttribute("data-searchstatus");
    var modal = document.getElementById("search-modal");
    var input = document.getElementById("search-textbox");
    var closetext = document.getElementById("search-close");
    var closetextmob = document.getElementById("search-close-mob");
    var icon = document.getElementById("search-icon");
    var iconmob = document.getElementById("search-icon-mob");
    var menubutton = document.getElementById("menu-button");
    var menustatus = menubutton.getAttribute("data-menustatus");

    if (menustatus === "o") {
        menuToggle();
    } else false;

    if (searchstatus === "c") {
        modal.classList.remove("-translate-y-full");
        // searchbutton.innerHTML = "Close";
        icon.classList.add("hidden");
        iconmob.classList.add("hidden");
        closetext.classList.remove("hidden");
        closetextmob.classList.remove("hidden");
        searchbutton.setAttribute("data-searchstatus", "o");
        setTimeout(function() {input.focus();
            input.select();}, 500)
        
    } else {
        modal.classList.add("-translate-y-full");
        // searchbutton.innerHTML = "Search";
        searchbutton.setAttribute("data-searchstatus", "c");
        icon.classList.remove("hidden");
        iconmob.classList.remove("hidden");
        closetext.classList.add("hidden");
        closetextmob.classList.add("hidden");
    };


};



//   ---------------------------------- Isotope ----------------------------------

var isogrid = document.getElementsByClassName("iso-grid");
if (isogrid.length > 0) {


    var iso = new Isotope('.iso-grid', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
            opacity: 0
        },
        visibleStyle: {
            opacity: 1
        },
    });

    function filterNews(filter) {
        iso.arrange({
            filter: filter
        });
        iso.layout();
    };

    window.filterNews = filterNews;



    let path = window.location.href;
    let searchpath = window.location.search;
    console.log(searchpath);
    console.log("Path" + path);

    var infscrollelem = document.querySelector('.iso-grid');
    // console.log(infscrollelem);
    if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinite1")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.grid-item',
            history: false,
            outlayer: iso,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso.layout();
        });
    } else if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinitesearch")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.search-item',
            history: false,
            outlayer: iso,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso.layout();
        });
    } else false;


    document.addEventListener('lazyloaded', function (e) {
        iso.layout();
    });




} else false;




var isogrid2 = document.getElementsByClassName("iso-grid2");
if (isogrid2.length > 0) {


    var iso2 = new Isotope('.iso-grid2', {
        itemSelector: '.grid-item2',
        stamp: '.stamp',
        hiddenStyle: {
            opacity: 0
        },
        visibleStyle: {
            opacity: 1
        },
    });



    let path = window.location.href;
    let searchpath = window.location.search;
    console.log(searchpath);
    // console.log("Path" + path);

    var infscrollelem = document.querySelector('.iso-grid2');
    // console.log(infscrollelem);
    if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinite1")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.grid-item2',
            history: false,
            outlayer: iso2,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso2.layout();
        });
    } else if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinitesearch")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.search-item',
            history: false,
            outlayer: iso2,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso2.layout();
        });
    } else false;

    document.addEventListener('lazyloaded', function (e) {
        iso2.layout();
    });

} else false;






var isogrid3 = document.getElementsByClassName("iso-grid3");
if (isogrid3.length > 0) {

    var iso3 = new Isotope('.iso-grid3', {
        itemSelector: '.grid-item3',
        stamp: '.stamp',
        hiddenStyle: {
            opacity: 0
        },
        visibleStyle: {
            opacity: 1
        },
    });



    let path = window.location.href;
    let searchpath = window.location.search;
    console.log(searchpath);
    // console.log("Path" + path);

    var infscrollelem = document.querySelector('.iso-grid3');
    // console.log(infscrollelem);
    if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinite1")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.grid-item3',
            history: false,
            outlayer: iso3,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso3.layout();
        });
    } else if (typeof (infscrollelem) != 'undefined' && infscrollelem != null && infscrollelem.classList.contains("infinitesearch")) {
        let infScroll = new InfiniteScroll(infscrollelem, {
            // options
            path: path + '/page:{{#}}/' + searchpath,
            append: '.search-item',
            history: false,
            outlayer: iso3,
        });

        infScroll.on('append', function () {
            imageHeightFix();
            iso3.layout();
        });
    } else false;

    document.addEventListener('lazyloaded', function (e) {
        iso3.layout();
    });




} else false;



var isogridpack = document.getElementsByClassName("iso-grid-pack");
if (isogridpack.length > 0) {

    imagesLoaded('.iso-grid-pack', function () {
        var isopack = new Isotope('.iso-grid-pack', {
            itemSelector: '.grid-item',
            stamp: '.stamp',
            layoutMode: 'packery',

        });

        var options = {
            valueNames: ['entry-name', 'unit', 'studio', 'entry-practices']
        };

        var userList = new List('studio-directory', options);

        userList.on("updated", function () {
            isopack.layout()
        });


        function filterDirectory(building) {
            isopack.arrange({
                filter: building
            });
        };

        window.filterDirectory = filterDirectory;

    });

} else false;



// ------------------------------  Infinite Scroll ------------------------------


// let path = window.location.pathname;
// let searchpath = window.location.search;
// console.log(searchpath);
// // console.log("Path" + path);

// var infscrollelem = document.querySelector('.iso-grid');
// // console.log(infscrollelem);
// if (typeof(infscrollelem) != 'undefined' && infscrollelem != null  && infscrollelem.classList.contains("infinite1")) {
//     let infScroll = new InfiniteScroll( infscrollelem, {
//     // options
//     path: path + 'page:{{#}}/' + searchpath ,
//     append: '.grid-item',
//     history: false,
//     outlayer: iso,
//     });

//     infScroll.on( 'append', function() {
//         imageHeightFix();
//         iso.layout();
//     });
// } else if (typeof(infscrollelem) != 'undefined' && infscrollelem != null  && infscrollelem.classList.contains("infinitesearch")) {
//     let infScroll = new InfiniteScroll( infscrollelem, {
//         // options
//         path: path + 'page:{{#}}/' + searchpath ,
//         append: '.search-item',
//         history: false,
//         outlayer: iso,
//         });

//         infScroll.on( 'append', function() {
//             imageHeightFix();
//             iso.layout();
//         });
//  } else false;



//   ---------------------------------- Exhibition page info drawer ----------------------------------

function toggleInfo() {
    var info = document.getElementById("info-container");
    var status = info.getAttribute("data-status");
    var button = document.getElementById("info-button");
    if (status === "c") {
        info.classList.remove("translate-y-full");
        info.setAttribute("data-status", "o");
        button.innerHTML = "Close info";
    } else {
        info.classList.add("translate-y-full");
        info.setAttribute("data-status", "c");
        button.innerHTML = "More info";
    };
};

window.toggleInfo = toggleInfo;




//   ---------------------------------- Toggle site map ----------------------------------

function toggleSiteMap() {
    var map = document.getElementById("building-sitemap");
    var status = map.getAttribute("data-status");

    if (status === "c") {
        map.classList.remove("hidden");
        map.classList.add("flex");
        setTimeout(function () {
            map.classList.remove("opacity-0")
        }, 100);
        map.setAttribute("data-status", "o");
    } else {
        map.classList.add("opacity-0");
        map.classList.remove("flex");
        setTimeout(function () {
            map.classList.add("hidden")
        }, 500);
        map.setAttribute("data-status", "c");
    };
};

window.toggleSiteMap = toggleSiteMap;


//   ---------------------------------- Toggle registration form ----------------------------------

function toggleRegistrationForm() {
    var form = document.getElementById("studios-registration-form");
    var status = form.getAttribute("data-status");

    if (status === "c") {
        form.classList.remove("hidden");
        setTimeout(function () {
            form.classList.remove("opacity-0")
        }, 100);
        form.setAttribute("data-status", "o");
    } else {
        form.classList.add("opacity-0");
        setTimeout(function () {
            form.classList.add("hidden")
        }, 500);
        form.setAttribute("data-status", "c");
    };
};

window.toggleRegistrationForm = toggleRegistrationForm;



//   ---------------------------------- Toggle print form ----------------------------------

function togglePrintForm() {
    var form = document.getElementById("print-membership");
    var status = form.getAttribute("data-status");

    if (status === "c") {
        form.classList.remove("hidden");
        setTimeout(function () {
            form.classList.remove("opacity-0")
        }, 100);
        form.setAttribute("data-status", "o");
    } else {
        form.classList.add("opacity-0");
        setTimeout(function () {
            form.classList.add("hidden")
        }, 500);
        form.setAttribute("data-status", "c");
    };
};

window.togglePrintForm = togglePrintForm;




//   ---------------------------------- Image Height Fix ----------------------------------


function imageHeightFix() {

    if (CSS.supports("aspect-ratio: 1 / 1")) {


        // Should only need to add this to the 'else' part of the if statement. This is a temporary fix for a safari bug.

        var images = document.getElementsByClassName("lazyload");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");



            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };

            console.log(newheight);

        };

        document.addEventListener('lazyloaded', function (e) {
            if (e.target.parentElement.classList.contains("imagebackground")) {
                e.target.parentElement.style.height = 'auto';
            } else {
                e.target.style.height = 'auto';
            };
        });

    } else {
        var images = document.getElementsByClassName("lazyload");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");



            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };

            console.log(newheight);

        };

        document.addEventListener('lazyloaded', function (e) {
            if (e.target.parentElement.classList.contains("imagebackground")) {
                e.target.parentElement.style.height = 'auto';
            } else {
                e.target.style.height = 'auto';
            };
        });
    };
};






//   ---------------------------------- Filter Directory ----------------------------------

var options = {
    valueNames: ['entry-name', 'unit', 'studio', 'entry-practices', 'entry-building']
};

var userList = new List('studio-directory', options);

function filterDirectory(building) {
    userList.search(building);
};

window.filterDirectory = filterDirectory;






//   ---------------------------------- Building Map Zoom ----------------------------------

document.addEventListener('DOMContentLoaded', function () {
    const zooming = new Zooming({
      onOpen: function (target) {
          target.classList.remove("object-cover");
          target.classList.add("object-contain");
          target.classList.remove("border-2");
      },
      onClose: function (target) {
          target.classList.add("object-cover");
          target.classList.remove("object-contain");
          target.classList.add("border-2");
      }
    })
  
    zooming.listen('.img-zoomable')
  })



// var dragItem = document.querySelector("#menu-button");
// var container = document.querySelector("body");

// var active = false;
// var currentX;
// var currentY;
// var initialX;
// var initialY;
// var xOffset = 0;
// var yOffset = 0;

// container.addEventListener("touchstart", dragStart, false);
// container.addEventListener("touchend", dragEnd, false);
// container.addEventListener("touchmove", drag, false);

// container.addEventListener("mousedown", dragStart, false);
// container.addEventListener("mouseup", dragEnd, false);
// container.addEventListener("mousemove", drag, false);

// function dragStart(e) {
//   if (e.type === "touchstart") {
//     initialX = e.touches[0].clientX - xOffset;
//     initialY = e.touches[0].clientY - yOffset;
//   } else {
//     initialX = e.clientX - xOffset;
//     initialY = e.clientY - yOffset;
//   }

//   if (e.target === dragItem) {
//     active = true;
//   }
// }

// function dragEnd(e) {
//   initialX = currentX;
//   initialY = currentY;

//   active = false;
// }

// function drag(e) {
//   if (active) {

//     e.preventDefault();

//     if (e.type === "touchmove") {
//       currentX = e.touches[0].clientX - initialX;
//       currentY = e.touches[0].clientY - initialY;
//     } else {
//       currentX = e.clientX - initialX;
//       currentY = e.clientY - initialY;
//     }

//     xOffset = currentX;
//     yOffset = currentY;

//     setTranslate(currentX, currentY, dragItem);
//   }
// }

// function setTranslate(xPos, yPos, el) {
//   el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
// }